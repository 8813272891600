<template>
  <div
    class="slot"
    :class="{
      'slot--free': detailedOrder.isFreeSlot,
    }"
  >
    <div class="slot__info">
      <div class="slot__time">
        <div class="slot__marker" />

        <div class="slot__time-wrapper">
          <div class="slot__start">
            {{ startTime }}
          </div>
          <div class="slot__end">
            {{ endTime }}
          </div>
        </div>
      </div>

      <div
        v-if="!detailedOrder.isFreeSlot"
        class="slot__details"
      >
        <div class="slot__order">
          {{ orderId }}
        </div>

        <div class="slot__student">
          {{ studentFullname }}
        </div>
      </div>
    </div>

    <router-link
      v-if="!detailedOrder.isFreeSlot"
      class="slot__link" 
      :to="linkToOrder"
    >
      <img src="@/assets/icons/arrow-grey.svg" alt="Перейти к заказу">
    </router-link>
    <p
      v-else
      class="slot__free-text"
    >
      Слот свободен
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Moment from 'moment'

export default defineComponent({
  name: 'SlotItem',
  props: {
    detailedOrder: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    startTime() {
      if (this.detailedOrder.timeInterval) {
        return Moment(this.detailedOrder.timeInterval.start).format('HH:mm')
      }

      return ''
    },
    endTime() {
      if (this.detailedOrder.timeInterval) {
        return Moment(this.detailedOrder.timeInterval.end).format('HH:mm')
      }

      return ''
    },
    orderId() {
      return `Заказ №${this.detailedOrder.id || ''}`
    },
    linkToOrder() {
      return this.detailedOrder.id ? `/orders/${this.detailedOrder.id}` : '/orders?page=1'
    },
    studentFullname() {
      if (this.detailedOrder.student) {
        const firstName = this.detailedOrder.student.attributes.firstName
        const lastName = this.detailedOrder.student.attributes.lastName

        return `${firstName} ${lastName}`
      }

      return ''
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/widgets/periods/styles/typography.scss';

.slot {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 65px;
  padding: 8px;
  border: 1px solid #F8F8F8;
  border-radius: 10px;
  transition: .3s all ease-in-out;
  &:hover {
    background-color: #F8F8F8;
  }
  & > div {
    height: 100%;
  }
  &__info {
    display: flex;
    gap: 16px;
    z-index: 10;
  }
  &__time {
    display: flex;
    gap: 8px;
  }
  &__time-wrapper,
  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__marker {
    width: 5px;
    height: 100%;
    margin-right: 8px;
    border-radius: 10px;
    background-color: #EB501F;
  }
  &__student,
  &__start,
  &__end,
  &__free-text {
    @include small_text;
  }
  &__order {
    @include subtitle;
  }
  &__free-text {
    color: #A2AE2D;
  }
  &__link::after {
    content: '';
    position: absolute;
    inset: 0;
    cursor: pointer;
  }
  &--free {
    .slot__marker {
      background-color: #A2AE2D;
    }
  }
}
</style>
