<template>
	<div
			v-if="columns.length"
			:class="$style.custom_table"
	>
		<div :class="$style.custom_table__columns">
			<div
					v-for="({column, width}, i) in columns"
					:key="i"
					:class="$style.custom_table__columns_column"
					:style="{width: width ? width + 'rem' : 3 + 'rem'}"
			>
<!--				<div :class="$style.custom_table__columns_column_sort">-->
<!--					<img src="@/assets/icons/arrow.svg" alt="topArrow">-->
<!--					<img src="@/assets/icons/arrow.svg" alt="topArrow">-->
<!--				</div>-->
				{{ column }}
			</div>
		</div>
		<div
				v-if="items.length"
				:class="$style.custom_table__rows"
		>
			<div
					v-for="(item, i) in items"
					:key="i"
					:class="$style.custom_table__rows_row"
			>
				<div
						v-for="({value, width}, i) in columns"
						:key="i"
						:style="{width: width ? width + 'rem' : 5 + 'rem'}"
						:title="value(item)"
				>
					{{ value(item) }}
				</div>
			</div>
		</div>
		<div
				v-else
				:class="$style.custom_table__empty"
		>
			Нет данных
		</div>
	</div>
</template>

<script>
  export default {
    name: 'CustomTable',
    props: {
      columns: {
        type: Array,
        default: () => []
      },
      items: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        list: []
      }
    },
    beforeMount () {
      if (this.columns.find(e => e.key === 'id')) {
        this.list = this.items.sort((a, b) => b.id - a.id)
      }
      this.list = this.items
    }
  }
</script>

<style lang="scss" module>
	.custom_table {
		display: flex;
		flex-direction: column;
		width: max-content;

		&__columns {
			display: flex;
			justify-content: space-between;

			&_column {
				padding: 0.5rem;
				display: flex;
				font-weight: 700;
				align-items: center;
				justify-content: center;
				width: 100%;
				border: 1px solid black;
				border-right: none;

				&:first-child {
					border-top-left-radius: 0.5rem;
				}

				&:last-child {
					border-right: 1px solid black !important;
					border-top-right-radius: 0.5rem;
				}

				&_sort {
					position: relative;
					margin-right: 0.7rem;
					img {
						position: absolute;
						cursor: pointer;
						height: 0.4rem;
						&:first-child {
							top: 50%;
						}
						&:last-child {
							bottom: 50%;
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		&__rows {
			display: flex;
			flex-direction: column;

			&_row {
				display: flex;

				div {
					font-size: 0.75rem;
					padding: 0.5rem;
					min-height: 1rem;
					display: flex;
					justify-content: center;
					width: 100%;
					border: 1px solid black;
					border-right: none;
					border-top: none;
					overflow: hidden;

					&:last-child {
						border-right: 1px solid black !important;
					}
				}
			}
		}

		&__empty {
			padding: 0.5rem;
			font-size: 0.75rem;
			border: 1px solid black;
			border-top: none;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
</style>
