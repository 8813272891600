<template>
  <div class="period-item">
    <div class="period-item__header">
      <div class="period-item__index">
        {{ index }} интервал
      </div>

      <div class="period-item__time">
        {{ periodTimeInterval }}
      </div>
    </div>

    <div class="period-item__slots">
      <slot-item
        v-for="detailedOrder in periodDetailOrders"
        :key="detailedOrder.timeInterval.start"
        :detailed-order="detailedOrder"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SlotItem from '@/widgets/periods/components/SlotItem.vue'
import Moment from 'moment'

const BREAK_DURATION = 10

export default defineComponent({
  name: 'PeriodItem',
  components: {
    SlotItem,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    period: {
      type: Object,
      default: () => ({}),
    },
    allOrders: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    periodTimeInterval() {
      const timeStart = Moment(this.period.start).format('HH:mm')
      const endTime = Moment(this.period.end).format('HH:mm')

      return `${timeStart} - ${endTime}`
    },
    periodDetailOrders() {
      const detailedOrders = this.findDetailOrders()
      return this.addFreeSlots(detailedOrders)
    },
  },
  methods: {
    findDetailOrders() {
      const ordersTimeIntervals = this.period.orders
      let detailedOrders = []

      detailedOrders = this.allOrders
        .filter((order) => {
          return ordersTimeIntervals.some((interval) => {
            return order.datetime === interval.start
          })
        })
        .map((order) => {
          const interval = ordersTimeIntervals.find((currentInterval) => {
            return currentInterval.start === order.datetime
          })
          return {
            ...order,
            timeInterval: interval,
            isFreeSlot: false,
          }
        })

      return detailedOrders.sort((a, b) => {
        return Moment(a.timeInterval.start).diff(Moment(b.timeInterval.start))
      })
    },
    addFreeSlots(detailedOrders) {
      const freeSlots = []
      let startTime = Moment(this.period.start)

      detailedOrders.forEach((order, index) => {
        const orderStartTime = Moment(order.timeInterval.start)
        const orderEndTime = Moment(order.timeInterval.end)

        if (orderStartTime.diff(startTime, 'minutes') >= BREAK_DURATION) {
          const freeSlotStart = index === 0 
            ? startTime
            : Moment(startTime)

          const freeSlotEnd = Moment(orderStartTime)

          if (freeSlotEnd.isAfter(freeSlotStart)) {
            freeSlots.push({
              isFreeSlot: true,
              timeInterval: {
                start: freeSlotStart.format('YYYY-MM-DD HH:mm:ss'),
                end: freeSlotEnd.format('YYYY-MM-DD HH:mm:ss'),
              },
            })
          }
        }

        startTime = Moment(orderEndTime).add(BREAK_DURATION, 'minutes')
      })

      if (Moment(this.period.end).diff(startTime, 'minutes') >= BREAK_DURATION) {
        freeSlots.push({
          isFreeSlot: true,
          timeInterval: {
            start: startTime.format('YYYY-MM-DD HH:mm:ss'),
            end: this.period.end,
          },
        })
      }

      return [...detailedOrders, ...freeSlots]
        .sort((a, b) => {
          return Moment(a.timeInterval.start).diff(Moment(b.timeInterval.start))
        })
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/widgets/periods/styles/typography.scss';

.period-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__index {
    width: max-content;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #F8F8F8;
    @include subtitle;
  }
  &__time {
    @include subtitle;
  }
  &__slots {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
