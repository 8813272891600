<template>
  <div class="periods-list">
    <h2 class="periods-list__title">
      {{ availablePeriods }}
    </h2>

    <div class="periods-list__content">
      <period-item
        v-for="(period, index) in periodsInChoosenDate"
        :key="period.id"
        :index="index + 1"
        :period="period"
        :all-orders="allOrders"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { pluralize } from '@/utils/string'
import PeriodItem from '@/widgets/periods/components/PeriodItem.vue'
import Moment from 'moment'

export default defineComponent ({
  name: 'PeriodsList',
  components: {
    PeriodItem,
  },
  props: {
    choosenDate: {
      type: String,
      default: () => '',
    },
    periods: {
      type: Array,
      default: () => [],
    },
    allOrders: {
      type: Array,
      default: ()=> [],
    },
  },
  computed: {
    availablePeriods() {
      const localeChoosenDate = Moment(this.choosenDate).locale('ru').format('LL')
      const periodsLength = this.periodsInChoosenDate.length
      const wordWithGoodEnding = 'интервал' + pluralize(periodsLength, '', 'а', 'ов')

      return `${localeChoosenDate} доступно ${periodsLength} ${wordWithGoodEnding}`
    },
    periodsInChoosenDate() {
      return this.periods
        .filter((period) => {
          return Moment(period.start).format('YYYY-MM-DD') === this.choosenDate
        })
        .sort((a, b) => Moment(a.start).diff(Moment(b.start)))
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/widgets/periods/styles/typography.scss';

.periods-list {
  display: grid;
  flex-direction: column;
  gap: 48px;
  &__title {
    @include title;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 335px);
    gap: 32px;
  }
}
</style>
