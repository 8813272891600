<template>
  <div class="teacher-periods">
    <ProfileCalendar
      :current-month="currentMonth"
      :current-date="currentDate"
      :highlighted-dates="highlightedDates"
      @prev-month-click="prevMonthHandler"
      @next-month-click="nextMonthHandler"
      @date-click="dateClickHandler"
    />

    <div v-if="periodsForRender[0] !== null" class="timeline" :style="{ '--range': range }">
      <!-- <p class="minTime">{{ String(minTime).padStart(2, '0') }}:00</p> -->
      <!-- <p class="maxTime">{{ String(maxTime).padStart(2, '0') }}:00</p> -->
      <p class="minTime">{{ minTime }}</p>
      <p class="maxTime">{{ maxTime }}</p>

      <div
        v-for="period in periodsForRender"
        :key="period.id"
        :title="cutSeconds(period.start) + ' – ' + cutSeconds(period.end)"
        class="period"
        :style="{
          '--start': timeRelativeToRange(period.start),
          '--end': ['00:00:00', '00:00'].includes(cutSeconds(period.end))
            ? 24
            : timeRelativeToRange(period.end)
        }"
      >
        <div
          v-for="lesson in period.orders || []"
          :key="lesson.id"
          :title="cutSeconds(lesson.start) + ' – ' + cutSeconds(lesson.end)"
          class="lesson"
          :style="{
            '--start': timeRelativeToRange(lesson.start) - timeRelativeToRange(period.start),
            '--end':
              (['00:00:00', '00:00'].includes(cutSeconds(period.end))
                ? 24
                : timeRelativeToRange(lesson.end)) - timeRelativeToRange(period.start)
          }"
        />

        <p
          class="hidden-mobile range"
          :style="{
            '--display-desktop':
              (['00:00:00', '00:00'].includes(cutSeconds(period.end))
                ? 24
                : timeRelativeToRange(period.end)) -
                timeRelativeToRange(period.start) >=
              6
                ? 'block'
                : 'none',
            '--display-tablet':
              (['00:00:00', '00:00'].includes(cutSeconds(period.end))
                ? 24
                : timeRelativeToRange(period.end)) -
                timeRelativeToRange(period.start) >=
              6
                ? 'block'
                : 'none'
          }"
        >
          {{ cutSeconds(period.start) }} –
          {{ cutSeconds(period.end) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileCalendar from './ProfileCalendar'
import { timeToFloat } from '@/utils/calendar'
import Moment from 'moment'

export default {
  name: 'Calendar',
  components: { ProfileCalendar },
  props: {
    periods: { type: Array, default: () => [] }
  },
  data() {
    return {
      minTime: 0,
      maxTime: 24,
      timezone: 3,

      currentMonth: [Moment().year(), Moment().month()],
      currentDate: Moment().format('YYYY-MM-DD')
    }
  },
  computed: {
    nextMonth() {
      const date = Moment(this.currentMonth).add(1, 'month')
      return [date.year(), date.month()]
    },
    highlightedDates() {
      return this.periods.map((p) => Moment(p.start).format('YYYY-MM-DD'))
    },
    periodsForRender() {
      return (
        this.periods.filter(
          (p) => Moment(p.start).format('YYYY-MM-DD') === this.currentDate
        ) ?? []
      )
    },
    range() {
      return this.maxTime - this.minTime
    }
  },
  methods: {
    prevMonthHandler() {
      const date = Moment(this.currentMonth).subtract(1, 'month')
      this.currentMonth = [date.year(), date.month()]
    },
    nextMonthHandler() {
      this.currentMonth = this.nextMonth
    },
    dateClickHandler(date) {
      this.currentDate = date
      this.$emit('set-date', date)
    },
    cutSeconds(value) {
      const time = Moment(value).utcOffset(this.timezone).format('HH:mm')
      const [hours, minutes] = time.split(/[.:]/)

      if (!hours || !minutes) {
        return 'Н/A'
      }

      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
    },
    timeRelativeToRange(time) {
      return Math.min(
        this.maxTime - this.minTime,
        Math.max(
          0,
          timeToFloat(Moment(time).utcOffset(this.timezone).format('HH:mm')) - this.minTime
        )
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors';
@import '@/assets/styles/breakpoints';

.timeline {
  margin-top: 2.56rem;

  --period-hour-width: calc(100% / var(--range));
  --body-height: #{2.5rem};

  height: var(--body-height);
  border-radius: 1.25rem;
  background-color: $white;
  position: relative;

  .addButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #62bb58;
    width: 2.5rem;
    height: 100%;
    border-radius: 50%;
    margin: 0 auto;
  }
}

.minTime,
.maxTime {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #c5c8cd;
  font-size: 0.75rem;
  font-weight: 300;
  user-select: none;
}
.minTime {
  left: 1.25rem;
}
.maxTime {
  right: 1.25rem;
}

.period {
  --lesson-hour-width: calc(100% / (var(--end) - var(--start)));

  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  background-color: #62bb58;
  left: calc(var(--start) * var(--period-hour-width));
  width: calc((var(--end) - var(--start)) * var(--period-hour-width));
  border-radius: calc(var(--body-height) / 2);

  display: flex;
  align-items: center;
}
.lesson {
  z-index: 0;
  position: absolute;
  background-color: $carmine-pink;
  top: 0;
  bottom: 0;
  border-radius: calc(var(--body-height) / 2);

  left: calc(var(--start) * var(--lesson-hour-width));
  width: calc((var(--end) - var(--start)) * var(--lesson-hour-width));
}
.range {
  z-index: 1;
  width: max-content;
  pointer-events: none;
  margin: 0 auto;
  color: $white;
  font-size: 0.75rem;
  user-select: none;
  text-align: center;

  display: var(--display-desktop);
  @include for-tablet {
    display: var(--display-tablet);
  }
}
</style>
