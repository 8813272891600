<template>
  <div :class="[$style.calendar, $style[type]]">
    <div :class="[$style.calendar__header, $style[type]]">
      <button
        class="prev-month-button"
        :class="[$style.changeMonthButton, $style[type]]"
        @click="prevMonthClickHandler"
      >
         <img style="cursor: pointer" src="@/assets/icons/chevron-left.svg" alt="пред" />
      </button>
      <p :class="[$style.monthName, $style[type]]">
        {{ getDate() }}
      </p>
      <button
        class="next-month-button"
        :class="[$style.changeMonthButton, $style[type]]"
        @click="nextMonthClickHandler"
      >
         <img style="cursor: pointer" src="@/assets/icons/chevron-right.svg" alt="след" />
      </button>
    </div>
    <div :class="$style.calendar__body">
      <p :class="[$style.dayweekName, $style[type]]">пн</p>
      <p :class="[$style.dayweekName, $style[type]]">вт</p>
      <p :class="[$style.dayweekName, $style[type]]">ср</p>
      <p :class="[$style.dayweekName, $style[type]]">чт</p>
      <p :class="[$style.dayweekName, $style[type]]">пт</p>
      <p :class="[$style.dayweekName, $style.weekend, $style[type]]">сб</p>
      <p :class="[$style.dayweekName, $style.weekend, $style[type]]">вс</p>

      <button
        v-for="day in calendarDates"
        :key="day.date"
        :class="[
          $style.day,
          $style[type],
          {
            [$style.hidden]:
              day.labels.includes('prev-month-day') || day.labels.includes('next-month-day'),
            [$style.current]: day.date === currentDate,
            [$style.highlighted]: highlightedDates.includes(day.date)
          }
        ]"
        @click="dateClickHandler(day.date)"
      >
        {{ new Date(day.date).getDate() }}
      </button>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'

import { getMonthDays } from '@/utils/calendar'

export default {
  name: 'ProfileCalendar',
  props: {
    currentMonth: { type: Array, default: () => [1970, 1] },
    currentDate: { type: String, default: '' },
    highlightedDates: { type: Array, default: () => [] },
    type: { type: String, default: '' }
  },
  computed: {
    calendarDates() {
      return getMonthDays(this.currentMonth)
    }
  },
  methods: {
    prevMonthClickHandler() {
      this.$emit('prev-month-click')
    },
    nextMonthClickHandler() {
      this.$emit('next-month-click')
    },
    dateClickHandler(date) {
      this.$emit('date-click', date)
    },
    getDate () {
      return Moment(this.currentMonth).format('MMMM YYYY')
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors';

.calendar {
  --calendar-cell-size: #{2.5rem};

  &.secondary {
    --calendar-cell-size: #{2.5rem};
  }

  &__header {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #bdbdbd;
    display: flex;
    align-items: center;

    &.secondary {
      padding: 0;
      border: none;
    }

    .changeMonthButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;

      &.secondary {
        margin: 0 1.75rem;
        path {
          stroke: $ferm-crayola;
        }
      }
    }
    .monthName {
      flex: 1;
      text-align: center;
      color: #828282;
      font-size: 1.25rem;
      font-weight: 500;
      text-transform: capitalize;

      &.secondary {
        font-size: 0.875rem;
        font-weight: 800;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: auto;
    grid-gap: 0.625rem calc((100% - var(--calendar-cell-size) * 7) / 6);
  }
}

.dayweekName {
  padding-top: 1.125rem;
  padding-bottom: 0.4375rem;

  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  text-align: center;

  &.weekend {
    color: $carmine-pink;
  }

  &.secondary {
    font-size: 0.625rem;
    font-weight: 600;
  }
}

.day {
  display: flex;
  width: var(--calendar-cell-size);
  height: var(--calendar-cell-size);
  justify-content: center;
  align-items: center;
  padding-top: 0.0625rem;

  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 50%;

  &.hidden {
    visibility: hidden;
  }
  &.highlighted {
    cursor: pointer;
    background-color: #62bb58;
    color: $white;
  }
  &.current {
    background-color: #fdc133;
    color: inherit;
  }

  &.secondary {
    font-size: 0.875rem;
    font-weight: 600;
  }
}
</style>
