var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"teacher-periods"},[_c('ProfileCalendar',{attrs:{"current-month":_vm.currentMonth,"current-date":_vm.currentDate,"highlighted-dates":_vm.highlightedDates},on:{"prev-month-click":_vm.prevMonthHandler,"next-month-click":_vm.nextMonthHandler,"date-click":_vm.dateClickHandler}}),(_vm.periodsForRender[0] !== null)?_c('div',{staticClass:"timeline",style:({ '--range': _vm.range })},[_c('p',{staticClass:"minTime"},[_vm._v(_vm._s(_vm.minTime))]),_c('p',{staticClass:"maxTime"},[_vm._v(_vm._s(_vm.maxTime))]),_vm._l((_vm.periodsForRender),function(period){return _c('div',{key:period.id,staticClass:"period",style:({
        '--start': _vm.timeRelativeToRange(period.start),
        '--end': ['00:00:00', '00:00'].includes(_vm.cutSeconds(period.end))
          ? 24
          : _vm.timeRelativeToRange(period.end)
      }),attrs:{"title":_vm.cutSeconds(period.start) + ' – ' + _vm.cutSeconds(period.end)}},[_vm._l((period.orders || []),function(lesson){return _c('div',{key:lesson.id,staticClass:"lesson",style:({
          '--start': _vm.timeRelativeToRange(lesson.start) - _vm.timeRelativeToRange(period.start),
          '--end':
            (['00:00:00', '00:00'].includes(_vm.cutSeconds(period.end))
              ? 24
              : _vm.timeRelativeToRange(lesson.end)) - _vm.timeRelativeToRange(period.start)
        }),attrs:{"title":_vm.cutSeconds(lesson.start) + ' – ' + _vm.cutSeconds(lesson.end)}})}),_c('p',{staticClass:"hidden-mobile range",style:({
          '--display-desktop':
            (['00:00:00', '00:00'].includes(_vm.cutSeconds(period.end))
              ? 24
              : _vm.timeRelativeToRange(period.end)) -
              _vm.timeRelativeToRange(period.start) >=
            6
              ? 'block'
              : 'none',
          '--display-tablet':
            (['00:00:00', '00:00'].includes(_vm.cutSeconds(period.end))
              ? 24
              : _vm.timeRelativeToRange(period.end)) -
              _vm.timeRelativeToRange(period.start) >=
            6
              ? 'block'
              : 'none'
        })},[_vm._v(" "+_vm._s(_vm.cutSeconds(period.start))+" – "+_vm._s(_vm.cutSeconds(period.end))+" ")])],2)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }