export const getMonthDays = ([year, month]) => {
  const firstDate = new Date(year, month)
  firstDate.setHours(0)

  const prevDays = getPrevDays(firstDate)
  const restMonthDays = getRestMonthDays(firstDate)
  const nextDays = getNextDays(firstDate)

  return [
    ...prevDays.map(createLabel('prev-month-day')),
    ...[firstDate, ...restMonthDays].map(createLabel('curr-month-day')),
    ...nextDays.map(createLabel('next-month-day'))
  ].map((day) => ({ ...day, date: formatDate(day.date) }))
}

const getPrevDays = (date) =>
  new Array(getWeekday(date))
    .fill()
    .map((d, i) => {
      return new Date(new Date(date).setDate(-i))
    })
    .reverse()
const getRestMonthDays = (date) => {
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  const daysLeft = new Date(lastDay).getDate() - 1

  return new Array(daysLeft).fill().map((d, i) => {
    return new Date(new Date(date).setDate(i + 2))
  })
}
const getNextDays = (date) => {
  const lastDay = new Date(date)
  lastDay.setMonth(date.getMonth() + 1)
  lastDay.setDate(0)

  return new Array(6 - getWeekday(lastDay)).fill().map((d, i) => {
    return new Date(new Date(lastDay).setDate(lastDay.getDate() + i + 1))
  })
}

/**
 * @param { string } label
 * @returns { (date: Date) => { type: string, date: Date, labels: string[] } }
 */
const createLabel = (label) => (date) => ({
  type: 'day',
  date,
  labels: [label]
})

/**
 * @param { Date } date
 */
export const getWeekday = (date) => (date.getDay() || 7) - 1

/**
 * unused
 */
export const getWeekNumber = (date) => {
  const d = stringToDate(date)
  const dayNum = d.getDay() || 7
  d.setDate(d.getDate() + 4 - dayNum)
  const yearStart = new Date(d.getFullYear(), 0, 1)
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
}

/**
 * getWeekNumber
 * export unused
 */
export const stringToDate = (date) => {
  const [y, m, d] = date.split('-')
  return new Date(Number(y), Number(m) - 1, Number(d))
}

export const formatDate = (date) => {
  const year = String(date.getFullYear())
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

/**
 * unused
 */
export const enumerateDaysBetweenDates = ({ startDate, endDate }, { $moment }) => {
  const dates = []

  const currDate = $moment(startDate).startOf('day')
  const lastDate = $moment(endDate).startOf('day')

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().format('YYYY-MM-DD'))
  }

  return dates
}

/**
 * unused
 */
export const compareDates = (a, b) => new Date(a) - new Date(b)

/**
 * @param {string} time e.g. `08:00:02`
 */
export const timeToFloat = (time) => {
  let [hours, minutes, seconds] = time.split(/[.:]/)
  hours = Number(hours) || 0
  minutes = Number(minutes) || 0
  seconds = Number(seconds) || 0
  return hours + minutes / 60 + seconds / 3600
}
