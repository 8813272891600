export function pluralize(num, firstForm, secondForm, thirdForm) {
  if (num % 100 < 5 || num % 100 > 20) {
    if (num % 10 === 1) {
      return firstForm
    } else if (num % 10 > 1 && num % 10 < 5) {
      return secondForm
    } else {
      return thirdForm
    }
  } else {
    return thirdForm
  }
}
